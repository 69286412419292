@media (min-width: 1600px) {
  .retroPlatformMusicSectionContainer {
    height: 75vh;
  }

  .retroPlatformMusicRowTitlesMainContainer {
      display: flex;
      color: #000000;
      align-items: center;
      cursor: pointer;
      padding: 2px;
      background: #F1F1F1;
      height: 28px;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    margin-left: 48px;
    display: flex;
  }

  .retroPlatformMusicTitleHeaderRowContainer {
    display: flex;
    background: #F1F1F1;
    margin-left: 140px;
    margin-top: 16px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
  }

  .retroPlatformMusicTitleHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 650px;
  }

  .retroPlatformMusicArtistsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 500px;
  }

  .retroPlatformMusicTitle {
    line-height: 16px;
    width: 500px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }


  .retroPlatformMusicRowPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicArtists {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 500px;
  }

  .retroPlatformMusicLikes {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
  }

  .retroPlatformMusicStreams {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
  }

  .retroPlatformMusicAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 1350px) and (max-width: 1599px) {
  .retroPlatformMusicSectionContainer {
    height: 75vh;
  }

  .retroPlatformMusicRowTitlesMainContainer {
      display: flex;
      color: #000000;
      align-items: center;
      cursor: pointer;
      padding: 2px;
      background: #F1F1F1;
      height: 28px;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    margin-left: 48px;
    display: flex;
  }

  .retroPlatformMusicTitleHeaderRowContainer {
    display: flex;
    background: #F1F1F1;
    margin-left: 140px;
    margin-top: 16px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
  }

  .retroPlatformMusicTitleHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 450px;
  }

  .retroPlatformMusicTitle {
    line-height: 16px;
    width: 450px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }


  .retroPlatformMusicRowPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicArtists {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 380px;
  }

  .retroPlatformMusicLikes {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
  }

  .retroPlatformMusicStreams {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
  }

  .retroPlatformMusicAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 1150px) and (max-width: 1349px) {
  .retroPlatformMusicSectionContainer {
    height: 75vh;
  }

  .retroPlatformMusicRowTitlesMainContainer {
    display: flex;
    color: #000000;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #F1F1F1;
    height: 28px;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    margin-left: 48px;
    display: flex;
  }

  .retroPlatformMusicTitleHeaderRowContainer {
    display: flex;
    background: #F1F1F1;
    margin-left: 140px;
    margin-top: 16px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
  }

  .retroPlatformMusicTitleHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 650px;
  }

  .retroPlatformMusicArtistsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 400px;
  }

  .retroPlatformMusicStreamsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 300px;
  }

  .retroPlatformMusicTitle {
    line-height: 16px;
    width: 200px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }


  .retroPlatformMusicRowPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicArtists {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 400px;
  }

  .retroPlatformMusicLikes {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
  }

  .retroPlatformMusicStreams {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
  }

  .retroPlatformMusicAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1149px) {
  .retroPlatformMusicSectionContainer {
    height: 75vh;
  }

  .retroPlatformMusicRowTitlesMainContainer {
    display: flex;
    color: #000000;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #F1F1F1;
    height: 28px;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    margin-left: 48px;
    display: flex;
  }

  .retroPlatformMusicTitleHeaderRowContainer {
    display: flex;
    background: #F1F1F1;
    margin-left: 140px;
    margin-top: 16px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
  }

  .retroPlatformMusicTitleHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 650px;
  }

  .retroPlatformMusicArtistsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 400px;
  }

  .retroPlatformMusicStreamsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 300px;
  }

  .retroPlatformMusicTitle {
    line-height: 16px;
    width: 250px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .retroPlatformMusicRowPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicArtists {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 200px;
  }

  .retroPlatformMusicLikes {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 40px;
  }

  .retroPlatformMusicAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatformMusicSectionContainer {
    height: 75vh;
  }

  .retroPlatformMusicRowTitlesMainContainer {
      display: none;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    margin-left: 48px;
    display: flex;
  }

  .retroPlatformMusicTitleHeaderRowContainer {
    display: flex;
    background: #F1F1F1;
    margin-left: 140px;
    margin-top: 16px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
  }

  .retroPlatformMusicTitleHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 650px;
  }

  .retroPlatformMusicArtistsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 400px;
  }

  .retroPlatformMusicStreamsHeaders {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 300px;
  }

  .retroPlatformMusicTitle {
    line-height: 16px;
    width: 250px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }


  .retroPlatformMusicRowPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicArtists {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 400px;
  }

  .retroPlatformMusicLikes {
    font-family: Neue Haas Unica;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 80px;
  }

  .retroPlatformMusicStreams {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
  }

  .retroPlatformMusicAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }
}

.retroPlatformMusicSpinner {
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}