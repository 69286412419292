.retroSilverBackground {
  background: radial-gradient(70.66% 168.65% at 26.21% 23.74%, #C4C3C3 0%, #9B9B9B 97.92%);
}

.retroMusicHeaderMainContainer {
  display: flex;
}

@media (min-width: 1350px) {
  .spacer {
    margin-right: 32px;
    width: 220px;
  }
}

@media (min-width: 1150px) and (max-width: 1349px) {
  .spacer {
    display: none;
  }

  .retroMusicSectionSearch {
    width: 200px;
    height: 31px;
    background: #F8F8F8;
    border-radius: 20px;
    border: none;
    font-size: 12px;
    padding-left: 10px;
    box-shadow: 0px 0px 4px #000000;
  }

  .retroMusicPlatformSearchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
    margin-top: 68px;
  }
}

.retroMusicPlatformContainer {
  display: flex;
  min-height: 100vh;
  background: radial-gradient(70.66% 168.65% at 26.21% 23.74%, #C4C3C3 0%, #9B9B9B 97.92%);
  flex-direction: column;
}

@media (min-width: 769px) {
  .retroMusicSectionSearch {
    width: 200px;
    height: 31px;
    background: #F8F8F8;
    border-radius: 20px;
    border: none;
    font-size: 12px;
    padding-left: 10px;
    box-shadow: 0px 0px 4px #000000;
  }

  .retroPlatformContainer {
    margin-right: 32px;
  }

  .retroMusicPlatformSearchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
    margin-top: 68px;
  }

  .retroMusicPlayerPlatformHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 32px;
    margin-right: 32px;
    width: 1500px;
  }
  .retroPlatformConnectWalletContainer {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
    font-size: 14px;
  }

  .createdbythe402 {
    align-items: center;
    color: #393A3E;
    font-size: 11px;
    position: absolute;
    bottom: 16px;
    left: 32px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroMusicSectionSearch {
    width: 300px;
    height: 24px;
    background: #F8F8F8;
    border-radius: 20px;
    border: none;
    font-size: 12px;
    padding-left: 10px;
    box-shadow: 0px 0px 4px #000000;
    margin-bottom: 8px;
    margin-top: 72px;
  }

  .retroMusicPlatformContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .retroMusicPlayerPlatformHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .retroMusicPlatformSearchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .retroPlatformContainer {
    width: 100vw;
  }

  .retroPlatformConnectWalletContainer {
    position: absolute;
    font-size: 10px;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
  .createdbythe402 {
    display: none;
  }
}

.retroMusicSectionSearch:focus {
  outline: none;
  box-shadow: 0px 0px 4px #3D7FE4;

}

.retroMusicPlatformBodyContainer {
  display: flex;
  justify-content: center;
}

@media (min-width: 769px) and (max-width: 929px) {
  .retroPlatformContainer {
    width: 180px;
  }
  .retroPlatformConnectWalletContainer {
    position: absolute;
    right: 32px;
    top: 20px;
    cursor: pointer;
    font-size: 14px;
  }
}
