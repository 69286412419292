@media (min-width: 930px) {
  .musicCoverPhoto {
    width: 220px;
  }

  .musicCoverPhotoEmpty {
    width: 220px;
    height: 220px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
  }

  .retroSelectedItem {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.09deg, #D0D0D0 -19.38%, #A5A5A5 84.84%);
    border: 0.2px solid #000000;
    box-sizing: border-box;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #393A3E;
  }

  .retroPlatformMusicCoverPhotoContainer {
    display: flex;
    flex-direction: column;
    width: 220px;
  }
}

@media (min-width: 769px) and (max-width: 929px) {
  .musicCoverPhoto {
    width: 180px;
  }
  .musicCoverPhotoEmpty {
    width: 180px;
    height: 180px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
  }
  .retroSelectedItem {
    width: 180px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.09deg, #D0D0D0 -19.38%, #A5A5A5 84.84%);
    border: 0.2px solid #000000;
    box-sizing: border-box;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #393A3E;
  }
  .retroPlatformMusicCoverPhotoContainer {
    display: flex;
    flex-direction: column;
    width: 150px;
  }

  .retroSelectedItem {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.09deg, #D0D0D0 -19.38%, #A5A5A5 84.84%);
    border: 0.2px solid #000000;
    box-sizing: border-box;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #393A3E;
  }
}


@media (min-width: 320px) and (max-width: 768px) {
  .musicCoverPhoto {
    width: 150px;
  }
  .musicCoverPhotoEmpty {
    width: 150px;
    height: 150px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
  }

  .retroSelectedItem {
    display: none;
  }

  .retroPlatformMusicCoverPhotoContainer {
    display: flex;
    flex-direction: column;
    width: 150px;
  }

  .musicCoverPhoto {
    width: 100px;
  }

  .retroPlatformMusicCoverPhotoInnerContainer {
    height: 100px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .musicCoverPhotoEmpty {
    width: 100px;
    height: 100px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
  }
}
