.platformSidebarContainer {
  background: #181818;
  width: 220px;
  top: 0px;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}


@media (min-width: 930px) {
  .retroPlatformSideBar {
    height: 75vh;
    margin-top: 32px;
  }

  .retroPlatformSidebarContainer {
    background: #EDF0F8;
    width: 220px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: calc(75vh - 252px);
  }

  .musicCoverPhoto-Container {
    width: 220px;
  }

  .retroPlatformSidebarOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 769px) and (max-width: 929px) {
  .retroPlatformContainer {
    width: 180px;
  }
  .retroPlatformSideBar {
    height: 75vh;
    margin-top: 32px;
  }

  .retroPlatformSidebarContainer {
    background: #EDF0F8;
    width: 180px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: calc(75vh - 252px);
  }

  .musicCoverPhoto-Container {
    width: 180px;
  }

  .retroPlatformSidebarOuterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatformSideBar {
    margin-top: 8px;
  }

  .retroPlatformSidebarContainer {
    background: #EDF0F8;
    width: 100vw;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .retroPlatformSidebarOuterContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .musicCoverPhoto-Container {
    width: 150px;
  }
}


.retroLibrary {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(92.09deg, #D0D0D0 -19.38%, #A5A5A5 84.84%);
  border: 0.2px solid #000000;
  box-sizing: border-box;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #393A3E;
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroLibrary {
    height: 24px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(92.09deg, #D0D0D0 -19.38%, #A5A5A5 84.84%);
    border: 0.2px solid #000000;
    box-sizing: border-box;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #393A3E;
  }
}

/* .walletAddress {
  margin-left: 16px;
  margin-top: 64px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  color: #787878;
}

.walletAddress-Container {
  display: flex;
  align-items: center;
}
.profile-picture {
  width: 36px;
  height: 36px;
}

.walletBalance {
  margin-left: 16px;
  margin-top: 8px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  color: #FFFFFF;
} */
