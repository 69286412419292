.platformMusicFullOwnersContainer {
	display: flex;
	flex-direction: column;
	overflow: scroll;
	left: calc(50% - 208px);
	top: calc(50% - 317px);
	position: fixed;
	background: #121317;
	box-shadow: 0px 0px 4px #787878;
	border-radius: 10px;
	width: 475px;
	height: 550px;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
	padding: 8px;
	opacity: .8;
}

.platformMusicFullOwners-SongName {
	color: #6CE9EA;
	margin-left: 8px;
	font-weight: 400;
	font-size: 16px;
}
.platformMusicFullOwners {
	display: flex;
	flex-direction: column;
}

.platformMusicFullOwners::-webkit-scrollbar {
	display: none;
}

.platformMusicFullOwners-Header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 475px;
	margin-top: 8px;
	margin-bottom: 8px;
	height: 32px;
}

.platformMusicOwner {
	color: #FFFFFF;
	margin-top: 8px;
	margin-bottom: 8px;
	margin-left: 8px;
	font-size: 14px;
	text-decoration: none;
}

.platformMusicOwner:hover {
	text-decoration: underline;
}

.platformMusicFullOwnersXButton {
	color: #FFFFFF;
	cursor: pointer;
	margin-right: 8px;
	z-index: 2147483647;
}

.platformMusicAlbumArt {
  width: 40px;
  height: 40px;
  background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
}
