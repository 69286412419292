.platformNavbarContainer {
  background: #121317;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #202020;
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 2;
}

.signUpLoginContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-right: 32px;
}

.maticBalance {
  margin-top: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}
.accountSettings {
  width: 189px;
  height: 194px;
  background: #121317;
  box-shadow: 0px 0px 4px #787878;
  border-radius: 10px;
  position: absolute;
  right: 16px;
  top: 72px;
}

.accountSettingsContainer {
  display: flex;
  flex-direction: column;
}

.walletAddress {
  margin-left: 16px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  color: #787878;
}

.walletAddressString {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 8px;
  color: #A9ADB5;
}

.walletAddress-Container {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 32px;
}

.walletCopyString {
  position: fixed;
  right: 30px;
  top: 40px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
}

.profile-picture {
  width: 30px;
  height: 30px;
}

.walletBalance {
  font-weight: 400;
  font-size: 14px;
  margin-left: 16px;
  margin-top: 16px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  color: #A9ADB5;
}

.walletBalanceToUSD {
  margin-left: 16px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #A9ADB5;
}

.disconnectWallet {
  margin-top: 60px;
  margin-left: 16px;
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #A9ADB5;
  cursor: pointer;
}
