@media (min-width: 768px)  {
  .retroPlatformMusicCoverPhotoInnerContainer {
    height: 220px;
  }
  .retroPlatformSidebarMenuItemsContainer {
    display: flex;
    flex-direction: column;
    height: 150px;
  }

  .retroPlatformSidebarMenuItemUnSelected {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    color: #505559;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }

  .retroPlatformSidebarMenuItemUnSelected:hover {
    color: #FFFFFF;
    background: #3D7FE4;
  }

  .retroPlatformSidebarMenuItemSelected {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #FFFFFF;
    background: #3D7FE4;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatformSidebarMenuItemsContainer {
    display: flex;
    height: 24px;
    justify-content: space-around;
  }

  .retroPlatformSidebarMenuItemUnSelected {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    color: #505559;
    height: 24px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .retroPlatformSidebarMenuItemUnSelected:hover {
    color: #FFFFFF;
    background: #3D7FE4;
  }
  .retroPlatformSidebarMenuItemSelected {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    color: #FFFFFF;
    background: #3D7FE4;
    height: 24px;
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
