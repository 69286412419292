.platformMusicRowContainer {
  display: flex;
  width: 100vw;
}

.platformMusicRowMainContainer{
  display: flex;
  color: #949191;
  height: 52px;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  justify-content: center;
}

.platformMusicCoverPhoto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.platformMusicRowMainContainer:hover {
  color: #66D3D5;
}

.platformMusicNumber {
  width: 20px;
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #787878;
}

.platformMusicHeader-AlbumArtwork{
  width: 320px;
  height: 320px;
  max-width: none;
  background: linear-gradient(180deg, #1962EF 0%, #6D33B6 97.91%, #1962EF 97.92%);
}

.platformMusicSectionHeader {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #FFFFFF;
  display: flex;
}

.platformMusicNumber {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #787878;
}

.platformMusicRowTitle {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #FFFFFF;
  width: 380px;
  margin-left: 16px;
  white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.platformMusicRowTitle:hover {
  color: #66D3D5;
}

.platformMusicRowAudioPlayer {
  width: 376px;
  height: 50px;
}

.platformMusicRowArtists {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #787878;
  width: 400px;
}

.platformMusicRowStreams {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #787878;
  width: 400px;
}

.platformMusicRowStreams:hover {
  text-decoration: underline;
  color: #FFFFFF;
}

.platformMusicAlbumArt {
  width: 40px;
  height: 40px;
}

.waveFormContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 276px;
  background: transparent;
  margin-bottom: -12px;
  margin-right: 40px;
}

.wave {
  display: flex;
  flex-direction: column;
  width:  266px;
  height: 40px;
}

.platformMusicWaveform {
  width: 300px;
}

.platformMusicRowMainContainer:hover {
  background: #212525;
  color: #FFFFFF;
}
