@media (min-width: 769px) {
  .retroMusicPlatformBodyContainer {
    margin-left: 32px;
  }

  .retroPlatformBodyContainer {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    margin-top: 32px;
    max-height: 75vh;
    min-height: 75vh;
    overflow: scroll;
    margin-right: 32px;
  }

  .retroPlatformMusicSpinnerContainer {
    display: flex;
    width: 100%;
    height: 75vh;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroMusicPlatformBodyContainer {
    display: flex;
    flex-direction: column;
  }

  .retroPlatformBodyContainer {
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    max-height: calc(100vh - 412px);
    min-height: calc(100vh - 412px);
    overflow: scroll;
  }

  .retroPlatformMusicSpinnerContainer {
    display: flex;
    width: 100%;
    height: calc(100vh - 412px);
    align-items: center;
    justify-content: center;
  }
}

.retroPlatformBodyContainer::-webkit-scrollbar {
  width: 24px;
}

.retroPlatformBodyContainer::-webkit-scrollbar-track {
  background: #F9F9F9;
  box-shadow: inset 0px 4px 10px #231E16;
  border-radius: 20px;
}

.retroPlatformBodyContainer::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #6C9EF1 0%, #74B8FA 44.27%, #7FC6FD 61.98%, #95D7FF 83.33%, #6EA5F4 97.4%);
  border-radius: 40px;
  transform: rotate(-90deg);
}
