@media (min-width: 1600px) {
  .retroPlatformMusicRowContainer {
    width: 1500px;
  }
}

@media (min-width: 1350px) and (max-width: 1600px) {
  .retroPlatformMusicRowContainer {
    width: 1200px;
  }
}

@media (min-width: 1150px) and (max-width: 1349px) {
  .retroPlatformMusicRowContainer {
    width: 800px;
  }
}

@media (min-width: 769px) and (max-width: 1149px) {
  .retroPlatformMusicRowContainer {
    width: 600px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatformMusicRowContainer {
    width: 100vw;
  }
}

@media (min-width: 1600px) {
  .retroPlatformMusicRowTitleAritst-Container {
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicRowMainContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #FFFFFF;
    color: #231E16;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerAlternate {
    display: flex;
    color: #231E16;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #EDF2FE;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainer:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerAlternate:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerSelected {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #3D7FE4;
    min-height: 24px;
  }

  .retroPlatformMusicNumber {
    width: 20px;
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    display: flex;
  }

  .retroPlatformMusicNumber {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicRowTitle {
    line-height: 16px;
    width: 500px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .retroPlatformMusicRowTitle:hover {
    color: #FFFFFF;
  }

  .retroPlatformMusicRowAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicRowArtists {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 500px;
  }

  .retroPlatformMusicAlbumArt {
    width: 40px;
    height: 40px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
    margin-left: 16px;
  }

  .retroPlatformMusicHeart {
    font-size: 12px;
    height: 12px;
  }

  .retroPlatformMusicHeartsTotal {
    font-family: 'Neue Haas Unica';
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
  }

  .retroPlatformMusicRowUpvotes {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .retroPlatformMusicRowShares {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicSectionCopyMessage {
    position: relative;
    left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-size: 8px;
    color: #000000;
  }
}

@media (min-width: 1350px) and (max-width: 1599px) {
  .retroPlatformMusicRowTitleAritst-Container {
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicRowMainContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #FFFFFF;
    color: #231E16;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerAlternate {
    display: flex;
    color: #231E16;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #EDF2FE;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainer:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerAlternate:hover {
    background: #3D7FE4;
    color: #FFFFFF;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerSelected {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #3D7FE4;
    min-height: 24px;
  }

  .retroPlatformMusicNumber {
    width: 20px;
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    display: flex;
  }

  .retroPlatformMusicNumber {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicRowTitle {
    line-height: 16px;
    width: 450px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .retroPlatformMusicRowTitle:hover {
    color: #FFFFFF;
  }

  .retroPlatformMusicRowAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicRowArtists {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 380px;
  }

  .retroPlatformMusicAlbumArt {
    width: 40px;
    height: 40px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
    margin-left: 16px;
  }

  .retroPlatformMusicHeart {
    font-size: 12px;
    height: 12px;
  }

  .retroPlatformMusicHeartsTotal {
    font-family: 'Neue Haas Unica';
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
  }

  .retroPlatformMusicRowUpvotes {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .retroPlatformMusicRowShares {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicSectionCopyMessage {
    position: relative;
    left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-size: 8px;
    color: #000000;
  }
}

@media (min-width: 1150px) and (max-width: 1349px) {
  .retroPlatformMusicRowTitleAritst-Container {
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicRowMainContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #FFFFFF;
    color: #231E16;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerAlternate {
    display: flex;
    color: #231E16;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #EDF2FE;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainer:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerAlternate:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerSelected {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #3D7FE4;
    min-height: 24px;
  }

  .retroPlatformMusicNumber {
    width: 20px;
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    display: flex;
  }

  .retroPlatformMusicNumber {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicRowTitle {
    line-height: 16px;
    width: 200px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .retroPlatformMusicRowTitle:hover {
    color: #FFFFFF;
  }

  .retroPlatformMusicRowAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicRowArtists {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 400px;
  }

  .retroPlatformMusicAlbumArt {
    width: 40px;
    height: 40px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
    margin-left: 16px;
  }

  .retroPlatformMusicHeart {
    font-size: 12px;
    height: 12px;
  }

  .retroPlatformMusicHeartsTotal {
    font-family: 'Neue Haas Unica';
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
  }

  .retroPlatformMusicRowUpvotes {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .retroPlatformMusicRowShares {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicSectionCopyMessage {
    position: relative;
    left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-size: 8px;
    color: #000000;
  }
}

@media (min-width: 769px) and (max-width: 1149px) {
  .retroPlatformMusicRowTitleAritst-Container {
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicRowMainContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #FFFFFF;
    color: #231E16;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerAlternate {
    display: flex;
    color: #231E16;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #EDF2FE;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainer:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerAlternate:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerSelected {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #3D7FE4;
    min-height: 24px;
  }

  .retroPlatformMusicNumber {
    width: 20px;
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    display: flex;
  }

  .retroPlatformMusicNumber {
    font-family: Neue Haas Unica;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #787878;
  }

  .retroPlatformMusicRowTitle {
    line-height: 16px;
    width: 250px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .retroPlatformMusicRowTitle:hover {
    color: #FFFFFF;
  }

  .retroPlatformMusicRowAudioPlayer {
    width: 376px;
    height: 24px;
    margin-right: 24px;
  }

  .retroPlatformMusicRowArtists {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 200px;
  }

  .retroPlatformMusicAlbumArt {
    width: 40px;
    height: 40px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
    margin-left: 16px;
  }

  .retroPlatformMusicHeart {
    font-size: 12px;
    height: 12px;
  }

  .retroPlatformMusicHeartsTotal {
    font-family: 'Neue Haas Unica';
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
  }

  .retroPlatformMusicRowUpvotes {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 40px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .retroPlatformMusicRowShares {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 40px;
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicSectionCopyMessage {
    position: relative;
    left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-size: 8px;
    color: #000000;
  }
}

@media (min-width: 320px) and (max-width: 768px) {

  .retroPlatformMusicRowTitleAritst-Container {
    display: flex;
    flex-direction: column;
  }

  .retroPlatformMusicRowMainContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #FFFFFF;
    color: #231E16;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainerAlternate {
    display: flex;
    color: #231E16;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #EDF2FE;
    min-height: 24px;
  }

  .retroPlatformMusicRowMainContainer:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerAlternate:hover {
    background: #3D7FE4;
    color: #FFFFFF;
  }

  .retroPlatformMusicRowMainContainerSelected {
    display: flex;
    color: #FFFFFF;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    background: #3D7FE4;
    min-height: 24px;
  }

  .retroPlatformMusicSectionHeader {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    color: #FFFFFF;
    display: flex;
  }

  .retroPlatformMusicRowTitle {
    line-height: 16px;
    width: 200px;
    margin-left: 34px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .retroPlatformMusicRowTitle:hover {
    color: #FFFFFF;
  }

  .retroPlatformMusicRowAudioPlayer {
    display: none;
  }

  .retroPlatformMusicRowArtists {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 200px;
    margin-left: 34px;
    color: #585B62;
  }

  .retroPlatformMusicAlbumArt {
    width: 40px;
    height: 40px;
    background: linear-gradient(137.43deg, #6D33B6 21.15%, #1962EF 94.68%);
    margin-left: 16px;
  }

  .retroPlatformMusicHeart {
    font-size: 12px;
    height: 12px;
  }

  .retroPlatformMusicHeartsTotal {
    font-family: 'Neue Haas Unica';
    font-weight: 400;
    font-size: 12px;
    margin-left: 8px;
  }

  .retroPlatformMusicRowUpvotes {
    margin-left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .retroPlatformMusicRowShares {
    margin-left: 8px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    width: 80px;
    display: flex;
    align-items: center;
  }

  .retroPlatformMusicSectionCopyMessage {
    position: relative;
    left: 4px;
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-size: 8px;
    color: #000000;
  }

}
