.platformMusicSidebarContainer {
	display: flex;
	flex-direction: column;
	width: 240px;
	position: fixed;
	top: 62px;
	bottom: 0px;
  left: 0px;
	border-right: 1px solid #202020;
}

.platformMusicSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.platformMusicSectionHeader {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  color: #FFFFFF;
  margin-left: 48px;
  display: flex;
}

.platformMusicTitleHeaderRowContainer {
  display: flex;
  color: #949191;
  margin-left: 8px;
  margin-top: 16px;
}

.platformMusicTitleHeaders {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #949191;
  width: 436px;
}

.platformMusicHeaders {
	color: #66D3D5;
	cursor: pointer;
	margin-top: 16px;
	margin-left: 32px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.platformMusicHeaderSelected {
	color: #66D3D5;
	cursor: pointer;
	margin-top: 16px;
	margin-left: 32px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.platformMusicHeaderUnselected {
	color: #787878;
	cursor: pointer;
	margin-top: 16px;
	margin-left: 32px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.platformMusicArtistsHeaders {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #949191;
  width: 400px;
}

.platformMusicStreamsHeaders {
  font-family: Neue Haas Unica;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #949191;
  width: 300px;
}

.platformMusicItems{
  padding-bottom: 120px;
  width: 1200px;
  display: flex;
  flex-direction: column;
}

.platformMusicSectionSearch {
  width: 240px;
  margin-bottom: 20px;
  margin-left: 40px;
}

.platformMusicSectionSearch {
  width: 240px;
  padding: 10px;
  margin-top: 75px;
  background: #181818;
  box-shadow: 0px 0px 2px #787878;
  border: none;
  border-radius: 5px;
  color: #FFFFFF;
}

.platformMusicSectionSearch:focus {
    outline: none;
}

.platformMusicHeadersRetro {
	color: #000000;
	cursor: pointer;
	margin-top: 16px;
	margin-left: 32px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
}

.musicNFTPlatformLogo {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.musicNFTPlatformContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.musicNFTPlatformContainer:hover {
	color: #66D3D5;
}
