.platformCoverTitleContainer {
  display: flex;
  flex-direction: column;
}

.retroPlatformCoverTitle {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}

.retroPlatformSongBoxContainer {
  width: 600px;
  height: 64px;
  background: #DAE1C6;
  box-shadow: 0px 0px 5px #000000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  flex-direction: column;
}

.retroPlatformSongName {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  text-align: center;
  color: #000000;
}

.retroPlatformSongNameEmpty {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  text-align: center;
  color: #000000;
}

.retroPlatformAlbumName {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  margin-top: 4px;
  text-align: center;
  color: #000000;
}

.retroPlatform-scrollbarInfo{
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.retroPlatform-backButtonBackground, .retroPlatform-forwardButtonBackground{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  width: 28px;
  height: 28px;
  background: radial-gradient(50% 50% at 50% 50%, #FDFDFD 0%, #D3D0D0 100%);
  border: none;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.retroPlatform-playButtonBackground {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  width: 36px;
  height: 36px;
  background: radial-gradient(50% 50% at 50% 50%, #FDFDFD 0%, #D3D0D0 100%);
  border: none;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.retroPlatform-playButtonBox {
  background: radial-gradient(60.21% 60% at 50% 50%, #E4E2E2 0%, #B4B4B4 69%, #A1A1A1 100%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
}

.retroPlatform-backButtonBox, .retroPlatform-forwardButtonBox{
  background: radial-gradient(60.21% 60% at 50% 50%, #E4E2E2 0%, #B4B4B4 69%, #A1A1A1 100%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 4px;
}

.retroPlatform-playButton {
  width: 18px;
  height: 18px;
  color:#757575;
  margin-left: 2px;
}

.retroPlatform-forwardButton{
  width: 15px;
  height: 15px;
  color:#757575;
  margin-left: 2px;
}

.retroPlatform-playingButton{
  width: 15px;
  height: 15px;
  color:#4d4c4c;
  margin-left: 2px;
}

.retroPlatform-backButton{
  width: 15px;
  height: 15px;
  color:#757575;
}

.retroPlatformSong-currentTime {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #000000;
  margin-right: 4px;
}

.retroPlatformSong-remainingTime {
  font-family: 'Neue Haas Unica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #000000;
  margin-left: 4px;
}

.retroPlatformSong-buttons{
  margin-top: 24px;
  margin-right: 32px;
  display: flex;
  align-items: center;
}

@media (min-width: 1349px) {
  .retroPlatform-scrollbar {
    -webkit-appearance: none;
    width: 450px;
    height: 10px;
    border: 1px solid #000000;
    background-color: #DAE1C6;
    box-sizing: border-box;
  }

  .retroPlatform-ControlsAndSongContainer {
    display: flex;
    align-items: center;
  }

  .retroPlatformCoverContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 930px) and (max-width: 1349px){
  .retroPlatform-scrollbar {
    -webkit-appearance: none;
    width: 350px;
    height: 10px;
    border: 1px solid #000000;
    background-color: #DAE1C6;
    box-sizing: border-box;
  }

  .retroPlatform-ControlsAndSongContainer {
    display: flex;
    align-items: center;
  }

  .retroPlatformCoverContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 769px) and (max-width: 929px) {
  .retroPlatform-scrollbar {
    -webkit-appearance: none;
    width: 150px;
    height: 10px;
    border: 1px solid #000000;
    background-color: #DAE1C6;
    box-sizing: border-box;
  }

  .retroPlatformSongBoxContainer {
    width: 312px;
    height: 64px;
    background: #DAE1C6;
    box-shadow: 0px 0px 5px #000000;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    flex-direction: column;
  }

  .retroPlatform-ControlsAndSongContainer {
    display: flex;
    align-items: center;
  }

  .retroPlatformCoverContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatform-scrollbar {
    -webkit-appearance: none;
    width: 150px;
    height: 10px;
    border: 1px solid #000000;
    background-color: #DAE1C6;
    box-sizing: border-box;
  }

  .retroPlatformSongBoxContainer {
    width: 312px;
    height: 64px;
    background: #DAE1C6;
    box-shadow: 0px 0px 5px #000000;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    flex-direction: column;
  }

  .retroPlatformCoverContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 930px) and (max-width: 1349px) {
  .retroPlatformSongBoxContainer {
    width: 450px;
    height: 64px;
    background: #DAE1C6;
    box-shadow: 0px 0px 5px #000000;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    flex-direction: column;
  }

  .retroPlatformCoverContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

}

@media (min-width: 320px) and (max-width: 929px) {
  .retroPlatformSongName {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    margin-top: 10px;
    text-align: center;
    color: #000000;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .retroPlatformSongNameEmpty {
    font-family: 'Neue Haas Unica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    margin-top: 10px;
    text-align: center;
    color: #000000;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .retroPlatform-ControlsAndSongContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .retroPlatformSong-buttons{
    margin-top: 8px;
    display: flex;
    align-items: center;
    margin-right: 0px;
  }

  .retroPlatformCoverContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.retroPlatform-scrollbar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 6px;
  background: #0B0A0A;
  transform: rotate(-45deg);
  cursor: pointer;
}
