.platformMusicPlayerContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-left: 20%;
  margin-top: 20px;
}

.platformMusicPlayer-songInformation {
  color: white;
}

.backgroundBlurredImagePlatform {
  width: 100%;
  position:absolute;
  left: 0;
  right: 0;
  top: 64px;
  height: 300px;
}

#blurred {
  filter: blur(100px);
}

.title-box-wrapPlatform {
  width: 384px;
  height: 384px;
  transition: all 0.6s ease-out;
  perspective: 1000px;
  z-index: 2;
}

.platformMusicContainer {
  background: #181818;
  height: 110px;
  position: fixed;
  width: 100%;
  align-items: center;
  bottom: 0;
  z-index: 10;
  display: flex;
  border-top: 1px solid #202020;
}

.platformMusicPlayer-songInformationSection {
  color: white;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 0px;
  width: 300px;
  align-items: center;
}

.platformMusicPlayer-AlbumArtwork{
  width: 80px;
  height: 80px;
  max-width: none;
  background: linear-gradient(180deg, #1962EF 0%, #6D33B6 97.91%, #1962EF 97.92%);
}

.platformMusicPlayer-songInformation{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.platformMusicPlayerSongName{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  min-width: 100px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.platformMusicPlayerArtistName{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: 200px;
  color: #A9ADB5;
}

.platformMusicPlayerBoxContainer {
  background: #121317;
  border-radius: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.platformMusicPlayer-currentTime {
  margin-right: 5px;
}

.platformMusicPlayer-remainingTime {
  margin-left: 5px;
}

.platformMusicPlayer-playButtonBackground {
  width: 36px;
  height: 36px;
  background: #ffffff;
  border: none;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.platformMusicPlayer-forwardButton {
  width: 22px;
  height: 22px;
  color: #A9ADB5;
  margin-left: 18px;
}

.platformMusicPlayer-backButton {
  width: 22px;
  height: 22px;
  color: #A9ADB5;
  margin-right: 18px;
}

.platformMusicPlayer-playButton {
  width: 22px;
  height: 22px;
  color: #121317;
  margin-left: 4px;
}

.platformMusicPlayer-pauseButton {
  width: 22px;
  height: 22px;
  color: #121317;
}

.platformMusicPlayer-buttons {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
}

.platformMusicPlayer-scrollbarInfo {
  display: flex;
  width: 100%;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #A9ADB5;
}

.platformMusicPlayer-scrollbar {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background-color: #5D5D5D;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
}

.platformMusicPlayer-scrollbar::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }

  .platformMusicPlayer-scrollbar::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: pointer;
    box-shadow: -558px 0 0 558px #43e5f7;
  }
